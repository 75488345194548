.datepicker label {
    font-size: 14px;
}

.datepicker span {
    display: flex;
    flex-direction: column;
}

.wrap-content {
    display: flex;
    align-items: center;
    gap : 8px;
}

.error-message {
    margin-top: 4px;
    font-size: 14px;
}

.custom-spinner .p-progress-spinner-circle {
    animation: custom-progress-spinner-dash 1.5s ease-in-out infinite,
        custom-progress-spinner-color 6s ease-in-out infinite;
}

@keyframes custom-progress-spinner-color {
    0%,
    100% {
        stroke: var(--blue-400);
    }
}

.ether-datetime {
    border-radius: 4px;
    background: var(--very-darkish-blue);
    border: 1px solid var(--alternative-grey-blacked-dark);
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    color: rgba(255, 255, 255, 0.87);
    padding: 0.1rem;
}
.ether-datetime::-webkit-datetime-edit {
    /* font-size: 0.875rem; */
    padding: 0.4375rem 0.65625rem;
}
.ether-datetime::-webkit-datetime-edit-fields-wrapper {
}
.ether-datetime::-webkit-datetime-edit-text {
}
.ether-datetime::-webkit-datetime-edit-month-field {
}
.ether-datetime::-webkit-datetime-edit-day-field {
}
.ether-datetime::-webkit-datetime-edit-year-field {
}
.ether-datetime::-webkit-inner-spin-button {
}
.ether-datetime::-webkit-calendar-picker-indicator {
    /* background: orange; */
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 8px 0 0;
    padding: 4px;
}
