.content-wrap {
    padding: 24px 16px 16px 16px;
    margin: auto;
}

@media screen and (min-width: 1200px) {
    .content-wrap {
        padding: 24px var(--medium-vertical-border) 16px
            var(--medium-vertical-border);
    }
}

@media screen and (min-width: 2000px) {
    .content-wrap {
        padding: 24px var(--high-vertical-border) 16px
            var(--high-vertical-border);
    }
}
