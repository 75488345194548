.custom-dropdown label {
    font-size: 14px;
}

.wrap-content {
    display: flex;
    align-items: center;
    gap : 8px;
}

.custom-dropdown span {
    display: flex;
    flex-direction: column;
}

.error-message {
    margin-top: 4px;
    font-size: 14px;
}

.custom-spinner .p-progress-spinner-circle {
    animation: custom-progress-spinner-dash 1.5s ease-in-out infinite,
        custom-progress-spinner-color 6s ease-in-out infinite;
}

@keyframes custom-progress-spinner-color {
    0%,
    100% {
        stroke: var(--blue-400);
    }
}