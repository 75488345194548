.custom-input-text {
    display: inline-block;
}

.custom-input-text span {
    display: flex;
    flex-direction: column;
}

.custom-input-text label {
    font-size: 14px;
}

.input-text-error {
    margin-top: 2px;
}