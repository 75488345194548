.text-content, .text-content span {
    max-width: 50ch;
    word-wrap: break-word;
}

.text-content-large {
    display: flex;
    align-items: center;
}

.text-content .p-button-icon {
    font-size: 0.9em !important;
}