.date-badge {
    font-size : 0.75rem !important;
    font-weight: bold !important;
    padding: 0 0.5rem !important;
    min-width: 1.5rem !important;
    height: 1.5rem !important;
    line-height: 1.5rem !important;
    border-radius: 10px !important;
}

.date-badge:hover {
    cursor: default;
}