.isrequired-util.required {
    color: var(--darkish-red);
    margin-left: 8px;
}

.isrequired-util.optional {
    color : var(--text-grey);
    font-size: 11px;
    margin-left: 8px;
}
