.custom-input-checkbox {
    display: inline-block;
}

.custom-input-checkbox label {
    font-size: 14px;
}

.custom-input-checkbox span {
    display: flex;
    flex-direction: column;
}

.input-checkbox-error {
    margin-top: 2px;
}