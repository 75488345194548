:root {
    --medium-vertical-border: 44px;
    --high-vertical-border: 120px;
}

body {
    margin: 0;
    background: linear-gradient(180deg, #030518 0%, #040829 50%, #030518 100%);
    font-family: var(--font-family);
    color: var(--text-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
}

a {
    text-decoration: none;
    color: inherit;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.p-datatable {
    overflow-x: auto;
}

.p-datatable .p-datatable-tbody td {
    max-width: 180px;
    overflow-wrap: break-word;
}

.hash-test {
    margin: auto;
}
