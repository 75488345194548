.custom-input-number {
    display: inline-block;
}

.custom-input-number span {
    display: flex;
    flex-direction: column;
}

.custom-input-number label {
    font-size: 14px;
}

.input-number-error {
    margin-top: 2px;
}