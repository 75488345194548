.logo {
    display : inline-block;
    border-radius: 8px;
    padding: 8px;
}

.logo-hover:hover {
    cursor: pointer;
    transition: background-color 0.4s;
    background-color: rgba(255, 255, 255, 0.1);
}

.logo .t1 {
    color: var(--semaphore-green);
    font-size: 1.5em;
    text-transform: uppercase;
}

.logo .t2 {
    color: #fff;
}