.custom-input-textarea {
    display: inline-block;
}

.custom-input-textarea span {
    display: flex;
    flex-direction: column;
}

.custom-input-textarea label {
    font-size: 14px;
}

.input-textarea-error {
    margin-top: 2px;
}