.dialog-export {
    width: 30vw;
}

.dialog-export .dialog-export-content {
    display: flex;
    gap: 16px;
    flex-direction: column;
    margin: auto;
}

.dialog-export .dialog-limit-group {
    display: flex;
    gap: 8px;
    width: 100%;
}